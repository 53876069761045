<template>
    <div id="container">
        <div class="vx-row md:w-full sm:w-auto">
             <vx-tooltip class="position-icon" :text="$t('button.edit')" position="top">

                <feather-icon icon="EditIcon" class="action-success icon-btn"
                              svgClasses="h-5 w-5 hover:text-primary cursor-pointer"
                              @click="editRecord"/>
            </vx-tooltip>

             <vx-tooltip class="position-icon" :text="$t('button.delete')" position="top">
                <feather-icon icon="TrashIcon" class="action-err icon-btn"
                              svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                              @click="confirmAcceptDelete"/>
            </vx-tooltip>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        editRecord() {
            this.$router.push(`/user/edit-hardware-device?organizationBranchId=${this.params.data.organizationBranchId}&id=${this.params.data.id}`).catch(() => {
            })
        },
        confirmAcceptDelete() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Xác nhận duyệt`,
                text: `Bạn có chắc là muốn xóa thiết bị "${this.params.data.deviceName}" không ? `,
                accept: this.accept,
                acceptText: "Xác nhận",
                cancelText: "Huỷ"
            })
        },
        accept() {
            this.$vs.loading();
            this.$crm.post(`/device/hardware/delete-by-id?organizationBranchId=${this.params.data.organizationBranchId}&id=${this.params.data.id}`).then(() => {
                this.showDeleteSuccess();
                this.$store.commit('UPDATE_HARDWARE_DEVICE', true);
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        showDeleteSuccess() {
            this.$vs.notify({
                color: 'success',
                text: 'Xóa thiết bị thành công.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        }
    }
}
</script>

<template>
    <div>
        <!-- <vs-chip class="ag-grid-cell-chip">
        </vs-chip> -->
    </div>
</template>

<script>

export default {
    name: 'CellRendererStatus',
    data() {

    }
}
</script>